import {
  type RequestTemplatesQuery,
  useRequestTemplatesLazyQuery,
} from '@/modules/requests/graphql/requestTemplates.generated';
import { IRequestTemplate } from '@/modules/requests/types/requestTemplate';
import {
  type WorkOrderTemplatesQuery,
  useWorkOrderTemplateByIdLazyQuery,
  useWorkOrderTemplatesLazyQuery,
} from '@/modules/workOrders/graphql/workOrderTemplates.generated';
import type { IWorkOrderTemplate } from '@/modules/workOrders/types/workOrderTemplate';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useApplicationContext } from './ApplicationContext';

export type TemplateContextProps = {
  workOrderTemplates: IWorkOrderTemplate[];
  defaultWorkOrderTemplate: IWorkOrderTemplate | undefined;
  targetWorkOrderTemplate: IWorkOrderTemplate | undefined;
  defaultRequestWorkOrderTemplate: IWorkOrderTemplate | undefined;
  defaultRequestTemplate: IRequestTemplate | undefined;
  isLoadingWorkOrderTemplate: boolean;
  setTargetWorkOrderTemplate: (template?: IWorkOrderTemplate) => void;
  fetchAndSetDefaultWorkOrderTemplate: (templateId: number) => void;
  fetchAndSetTargetWorkOrderTemplate: (templateId: number) => void;
  fetchRequestWorkOrderTemplates: () => void;
  fetchRequestTemplates: () => void;
};

export type PropsType = {
  children: ReactNode;
};

const TemplateContext = createContext<TemplateContextProps>({
  workOrderTemplates: [],
  defaultWorkOrderTemplate: undefined,
  targetWorkOrderTemplate: undefined,
  defaultRequestWorkOrderTemplate: undefined,
  defaultRequestTemplate: undefined,
  isLoadingWorkOrderTemplate: false,
  setTargetWorkOrderTemplate: () => undefined,
  fetchAndSetDefaultWorkOrderTemplate: () => undefined,
  fetchAndSetTargetWorkOrderTemplate: () => undefined,
  fetchRequestWorkOrderTemplates: () => undefined,
  fetchRequestTemplates: () => undefined,
});

export const useTemplateContext = () => useContext(TemplateContext);

export const TemplateProvider = ({ children }: PropsType) => {
  const [workOrderTemplates, setWorkOrderTemplates] = useState<IWorkOrderTemplate[]>([]);
  const [defaultWorkOrderTemplate, setDefaultWorkOrderTemplate] = useState<IWorkOrderTemplate>();
  const [targetWorkOrderTemplate, setTargetWorkOrderTemplate] = useState<IWorkOrderTemplate>();
  const [defaultRequestWorkOrderTemplate, setDefaultRequestWorkOrderTemplate] =
    useState<IWorkOrderTemplate>();
  const [defaultRequestTemplate, setDefaultRequestTemplate] = useState<IRequestTemplate>();
  const [isLoadingWorkOrderTemplate, setIsLoadingWorkOrderTemplate] = useState<boolean>(false);

  const { projectId } = useApplicationContext();

  const [workOrderTemplatesQuery] = useWorkOrderTemplatesLazyQuery();

  const [workOrderTemplateByIdQuery] = useWorkOrderTemplateByIdLazyQuery();

  const onCompletedRequestTemplatesQuery = (data: RequestTemplatesQuery) => {
    const fetchedRequestTemplates = data.requestTemplates as IRequestTemplate[];
    setDefaultRequestTemplate(fetchedRequestTemplates[0]); // expect single template for now
  };

  const [fetchRequestTemplates] = useRequestTemplatesLazyQuery({
    onCompleted: onCompletedRequestTemplatesQuery,
  });

  const onCompletedWorkOrderTemplates = useCallback(
    (data: WorkOrderTemplatesQuery) => {
      const workOrderTemplates = data.workOrderTemplates as IWorkOrderTemplate[];
      setWorkOrderTemplates(workOrderTemplates);
      // NOTE: Set the first template as the default. Users can choose another template later.
      if (!defaultWorkOrderTemplate) setDefaultWorkOrderTemplate(workOrderTemplates[0]);
    },
    [defaultWorkOrderTemplate]
  );

  useEffect(() => {
    workOrderTemplatesQuery({
      variables: {
        projectIds: projectId ? [projectId] : undefined,
        useDefault: true,
      },
      onCompleted: onCompletedWorkOrderTemplates,
    });
  }, [onCompletedWorkOrderTemplates, projectId, workOrderTemplatesQuery]);

  const fetchWorkOrderTemplate = useCallback(
    async (templateId: number): Promise<IWorkOrderTemplate> => {
      const { data } = await workOrderTemplateByIdQuery({
        variables: { id: templateId },
      });

      if (!data) throw new Error('Failed to fetch data');

      return data.workOrderTemplateById as IWorkOrderTemplate;
    },
    [workOrderTemplateByIdQuery]
  );

  const fetchAndSetTargetWorkOrderTemplate = useCallback(
    async (templateId: number) => {
      setIsLoadingWorkOrderTemplate(true);

      const template = await fetchWorkOrderTemplate(templateId);
      setTargetWorkOrderTemplate(template);

      setIsLoadingWorkOrderTemplate(false);
    },
    [fetchWorkOrderTemplate]
  );

  const fetchAndSetDefaultWorkOrderTemplate = useCallback(
    async (templateId: number) => {
      const template = await fetchWorkOrderTemplate(templateId);
      setDefaultWorkOrderTemplate((prevTemplate) =>
        prevTemplate?.id === template.id ? prevTemplate : template
      );
    },
    [fetchWorkOrderTemplate]
  );

  const fetchRequestWorkOrderTemplates = useCallback(async () => {
    const { data } = await workOrderTemplatesQuery({
      variables: {
        useRequest: true,
      },
    });

    if (!data) throw new Error('Failed to fetch data');

    const workOrderTemplates = data.workOrderTemplates as IWorkOrderTemplate[];

    // NOTE: expect single template for now
    setDefaultRequestWorkOrderTemplate(workOrderTemplates[0]);
  }, [workOrderTemplatesQuery]);

  const value = useMemo(
    () => ({
      workOrderTemplates,
      defaultWorkOrderTemplate,
      targetWorkOrderTemplate,
      defaultRequestWorkOrderTemplate,
      defaultRequestTemplate,
      isLoadingWorkOrderTemplate,
      setTargetWorkOrderTemplate,
      fetchAndSetDefaultWorkOrderTemplate,
      fetchAndSetTargetWorkOrderTemplate,
      fetchRequestWorkOrderTemplates,
      fetchRequestTemplates,
    }),
    [
      workOrderTemplates,
      defaultWorkOrderTemplate,
      targetWorkOrderTemplate,
      defaultRequestWorkOrderTemplate,
      defaultRequestTemplate,
      isLoadingWorkOrderTemplate,
      fetchAndSetDefaultWorkOrderTemplate,
      fetchAndSetTargetWorkOrderTemplate,
      fetchRequestWorkOrderTemplates,
      fetchRequestTemplates,
    ]
  );

  return <TemplateContext.Provider value={value}>{children}</TemplateContext.Provider>;
};
